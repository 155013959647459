import React from "react";
import Pricing from "../../components/home/pricing";

export default function section() {
  return (
    <>
      <div className="py-12 antialiased text-gray-900">
        <div class="mb-8  dark:border-gray-700 md:mb-12 grid-cols-1 md:px-24">
          <Pricing />
        </div>
      </div>
    </>
  );
}
