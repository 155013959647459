import React from "react";

export default function logo() {
  return (
    <div className="w-full inline-flex flex-nowrap">
      <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
        <li>
          <img src='image/belif/Rectangle 316.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 310.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 311.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 312.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 313.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 314.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 315.png' alt='belif'/>
        </li>
      </ul>
      <ul
        className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
        aria-hidden="true"
      >
        <li>
          <img src='image/belif/Rectangle 316.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 310.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 311.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 312.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 313.png' alt='belif'/>
        </li>
        <li>
          <img src='image/belif/Rectangle 314.png' alt='belif'/>
        </li>
      </ul>
    </div>
  );
}
