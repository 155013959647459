import React, { useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { en } from "../../constants/common";

const Aboutus = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    let newErrors = {};

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userData.email.trim() || !emailRegex.test(userData.email)) {
      newErrors.email = "Valid email is required";
    }

    // Set errors in the state
    setErrors(newErrors);

    // Return true if there are no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update state with new input value
    setUserData((prevData) => (
      {
      ...prevData,
      [name]: value,
    }));

  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate inputs before submitting
    if (validateInputs()) {
      // Create the mailto link
      const mailtoLink = `mailto:${en.mailId}?subject=Mail%20From%20Application%20Contact%20Us&body=Hello%2C%0D%0AName%3A%20${userData.name}%0D%0AEmail%3A%20${userData.email}%0D%0APhone%3A%20${userData.phone}%0D%0AMessage%3A%20${userData.message}`;

      // Open the mail client
      window.location.href = mailtoLink;
    }
  };

  const inputStyle = "bg-slate-50 h-12 p-2 rounded border";

  return (
    <div>
      <div
        className="w-full bg-cover bg-center"
        style={{
          height: "100%",
          backgroundImage: "url(/image/contact.png)",
        }}
      >
        <div className="py-28">
          <Navbar />
          <div className="flex justify-center">
            <p className="text-xl text-center md:text-4xl lg:text-6xl font-bold text-white w-96">
              {en.contactUs.name}
            </p>
          </div>
        </div>
      </div>
      <div className="py-24">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-start-1 col-end-4 md:col-start-2 md:col-end-3">
            <div className="flex flex-col">
              <label className="font-bold">Name</label>
              <input
                className={
                  errors.name
                    ? "bg-slate-50 h-12 p-2 rounded border-2 border-rose-600  "
                    : inputStyle
                }
                placeholder="Full Name"
                name="name"
                value={userData.name}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>
          <div className="col-start-1 col-end-4 md:col-start-3">
            <div className="flex flex-col">
              <label className="font-bold">Email</label>
              <input
                className={
                  errors.email
                    ? "bg-slate-50 h-12 p-2 rounded border-2 border-rose-600"
                    : inputStyle
                }
                placeholder="Email"
                name="email"
                value={userData.email}
                onChange={(event) => handleChange(event)}
              />
              {errors.email && (
                <div className="text-red-500">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-start-1 col-end-4 md:col-start-2 md:col-end-3">
            <div className="flex flex-col">
              <label className="font-bold">Your Message</label>
              <textarea
                className="bg-slate-50 h-28 p-2 rounded border"
                placeholder="Message"
                name="message"
                value={userData.message}
                onChange={(event) => handleChange(event)}
              />
            </div>
          </div>
          <div className="col-start-1 col-end-4 md:col-start-3">
            <div className="flex flex-col">
              <label className="font-bold">Phone Number </label>
              <input
                type="number"
                className={
                  errors.phone
                    ? "bg-slate-50 h-12 p-2 rounded border-2 border-rose-600 "
                    : inputStyle
                }
                placeholder="Phone Number"
                name="phone"
                value={userData.phone}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-1 col-end-12 md:col-start-1 md:col-end-7 pt-4">
                <div className="flex">
                  <div className="bg-gray-100 flex justify-center items-center w-12 rounded p-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 29 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.6224 19.8401C28.0559 20.0569 28.381 20.5446 28.381 21.0865C28.381 21.1407 28.381 21.2491 28.381 21.3575L27.0805 26.9932C26.9179 27.5893 26.4302 27.9687 25.8341 27.9687C11.9072 27.9687 0.689845 16.7513 0.689845 2.82441C0.689845 2.22831 1.06918 1.7406 1.66527 1.57803L7.30105 0.277467C7.40943 0.277467 7.51781 0.223277 7.572 0.223277C8.1139 0.223277 8.60161 0.548418 8.81838 1.03613L11.4195 7.10543C11.4737 7.268 11.5279 7.43057 11.5279 7.59314C11.5279 8.02667 11.3111 8.406 11.0402 8.62276L7.73457 11.3323C9.73961 15.5591 13.0994 18.9189 17.3262 20.9239L20.0357 17.6183C20.2525 17.3474 20.6318 17.1306 21.0112 17.1306C21.2279 17.1306 21.3905 17.1848 21.5531 17.239L27.6224 19.8401Z"
                        fill="#379CF4"
                      />
                    </svg>
                  </div>
                  <div className="pl-5">
                    <p className="font-bold">Phone</p>
                    <p>9840406431</p>
                  </div>
                </div>
              </div>
              <div className="col-start-1 col-end-12 md:col-start-7 md:col-end-12 pt-4 md:pl-5">
                <div className="flex">
                  <div className="bg-gray-200 flex justify-center items-center w-12 rounded p-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 29 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.4825 0.936567C26.8915 0.936567 28.0837 2.12875 28.0837 3.5377V19.1445C28.0837 20.6076 26.8915 21.7456 25.4825 21.7456H2.93941C1.47628 21.7456 0.338283 20.6076 0.338283 19.1445V3.5377C0.338283 2.12875 1.47628 0.936567 2.93941 0.936567H25.4825ZM25.4825 3.5377H2.93941V5.7595C4.1316 6.78911 6.08244 8.30643 10.2009 11.5578C11.1221 12.2623 12.9104 13.9964 14.211 13.9422C15.4573 13.9964 17.2456 12.2623 18.1669 11.5578C22.2853 8.30643 24.2362 6.78911 25.4825 5.7595V3.5377ZM2.93941 19.1445H25.4825V9.11929C24.2362 10.0947 22.4479 11.5037 19.7926 13.6171C18.5462 14.5383 16.487 16.5975 14.211 16.5433C11.8808 16.5975 9.76738 14.5383 8.57519 13.6171C5.91987 11.5037 4.1316 10.0947 2.93941 9.11929V19.1445Z"
                        fill="#379CF4"
                      />
                    </svg>
                  </div>
                  <div className="pl-5">
                    <p className="font-bold">Mail</p>
                    <p>thirulogchand@yahoo.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-start-1 col-end-4 md:col-start-3 pt-4 pl-5">
            <div className="flex justify-end">
              <button
                onClick={handleSubmit}
                className="px-8 py-2 bg-blue-500 rounded text-white font-bold"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Aboutus;
