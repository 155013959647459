import React from "react";
import TitleWithLines from "../components/utils/TitleWithLines";

export default function Team({
  imageList,
  header,
  subParagraphHeader,
  subParagraph,
  headerBreak,
}) {
  return (
    <div
      className="w-full bg-cover bg-center"
      style={{ height: "100%", backgroundImage: "url(/image/banner.png)" }}
    >
      <div className="py-12 text-white">
        <div className="flex justify-center flex-col md:flex-row items-center">
          {imageList.map((item, index) => (
            <div key={index} className={"px-12 py-12"}>
              <div className="relative saturate-50 hover:saturate-150">
                <img src={item.image} alt={item.alt} />
                <div className="absolute bottom-0 left-0 p-4 text-white text-shadow">
                  <p className="text-sm md:text-xl font-extrabold tracking-wide uppercase antialiased">
                    {item.name}
                  </p>
                  <p>
                    <TitleWithLines
                      title={item.subName}
                      textColor={"text-white"}
                      isTwoSide={false}
                    />
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div>
            <h1 className="font-bold text-2xl lg:text-5xl uppercase">
              {header} <br />
              {headerBreak}
            </h1>
            <p className="pt-5">
              {subParagraph} <br />
              {subParagraphHeader}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
