import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Navbar() {
  const [isNavbar, setIsNavbar] = useState(false)
useEffect(() => {
  if(window.innerWidth < 741){
    const header = document.querySelector('nav');
    header.classList.add('bg-gray-900');
  }
},[])

if(window.innerWidth > 741) {
  window.addEventListener('scroll', function () {
    const header = document.querySelector('nav');
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      header.classList.add('bg-gray-900');
      header.classList.remove('bg-transparent');
      header.classList.remove('transition-color');
    } else {
      header.classList.add('bg-transparent');
      header.classList.add('transition-color');
      header.classList.remove('bg-gray-900');
      header.classList.remove('text-gray-500');
    }
  })};

  return (
    <nav className='fixed z-50 top-0 left-0 right-0 duration-500 ease-in-out'>
      <div className='container mx-auto px-6 py-3'>
        <div className='md:flex md:items-center md:justify-between'>
          <div className='flex justify-between items-center'>
            <a href='/' className='flex items-center text-white'>
              <img src='/image/logo.png' alt='logo' className='w-16 h-12' />
              <p className='text-md font-bold md:text-xl pl-2'>WELL WISHERS ADVENTURE CLUB</p>
            </a>
            <div className='flex md:hidden'>
              <button
                type='button'
                className=' text-white hover:text-gray-600 focus:outline-none focus:text-gray-600'
                aria-label='toggle menu'
                onClick={() => setIsNavbar(!isNavbar)}
              >
                <svg viewBox='0 0 24 24' className='h-6 w-6 fill-current'>
                  <path
                    fillRule='evenodd'
                    d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className={isNavbar ? 'block -mx-4 md:flex md:items-center' : 'hidden -mx-4 md:flex md:items-center'}>
            {[
              { text: 'Home', link: '/' },
              { text: 'Services', link: '/service' },
              { text: 'About Us', link: '/aboutus' },
              { text: 'Contact Us', link: '/contact' },
            ].map((item, index) => (
              <a
                key={index}
                href={item.link}
                className='block mx-4 mt-2 md:mt-0 text-sm uppercase font-bold text-gray-300 hover:text-white'
              >
                {item.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}
