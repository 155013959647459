import React from "react";
import TitleWithLines from "./utils/TitleWithLines";
import { logoReverse, logos } from "../constants/common";

export default function logoSection() {
  return (
    <>
      <div className="text-center font-bold py-8">
        <h1 className="uppercase antialiased">Our Clients</h1>
        <TitleWithLines
          title={"Your Success, Our Priority"}
          textColor={"text-gray-700"}
          isTwoSide={true}
        />
      </div>
      <div class="w-full inline-flex flex-nowrap pt-10">
        <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
          {logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.name} alt={logo.alt} className={"w-28"} />
            </li>
          ))}
          {logoReverse.map((logo, index) => (
            <li key={index}>
              <img src={logo.name} alt={logo.alt} className={logo.size} />
            </li>
          ))}
        </ul>
        <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
          {logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.name} alt={logo.alt} className={"w-28"} />
            </li>
          ))}
          {logoReverse.map((logo, index) => (
            <li key={index}>
              <img src={logo.name} alt={logo.alt} className={logo.size} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
