import React from "react";
import Accordion from "./accordian";
import TitleWithLines from '../utils/TitleWithLines'

export default function aboutUs() {
  return (
    <>
      <div className="bg-blue-100 py-10">
        <div className="text-center font-bold">
          <h1 className="uppercase antialiased">About Us</h1>
          <TitleWithLines title={'We live for the nature'} textColor={'text-gray-700'} isTwoSide={true}/>
        </div>
        <div className="flex flex-wrap flex-col md:flex-row justify-around">
          <p className="w-4/4 px-3 md:px-0 md:w-2/4 pt-12 basis-4/4 text-gray-700 antialiased font-semibold text-justify">
            We have conducted so far 35 treks and mountaineering programmes in
            places like Ooty, Kodai, Yelagiri, Yercaud, Chennai - Vandalur hills
            ( not now), Thiruneermalai, Chengalpattu, Thirukalikundrum, Padappai
            etc. Around 1500 people have been trained by us on adventure sports.
            We have also conducted parasailing near East coast beach, Trichy
            etc. So far we have not come across any kind safety lapse or major
            problems. We ensure to insure on all our major mountaineering
            programmes with best insurance company.
          </p>
          <Accordion />
        </div>
      </div>
    </>
  );
}
