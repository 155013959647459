import React from "react";
import "./flip.css";

export default function front({ image }) {
  return (
    <div className="absolute inset-0 w-full h-full flex justify-center bg-white items-center bg-gray-900 transition-all duration-100 delay-200 z-20 hover:opacity-0">
      <div className="bg-white">
        <img class="w-full" src={image} alt="" />
      </div>
    </div>
  );
}
