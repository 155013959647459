import React from "react";
import { en } from "../constants/common";

export default function Footer() {
  return (
    <>
      <footer
        style={{ backgroundColor: "#13304A" }}
        className=" text-white dark:bg-gray-900"
      >
        <div className="mx-auto w-full max-w-screen-xl">
          <div className="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-12">
            <div className="md:col-start-1 md:col-end-4">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase text-wrap leading-loose dark:text-white">
               {en.companyName}
              </h2>
              <p className="mb-6 text-sm text-gray-400 dark:text-white">
                Somewhere between the bottom of the climb and the summit is the
                answer to the mystery why we climb.
              </p>
            </div>
            <div className="px-2 md:col-start-5 md:col-end-8">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                Contacts
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium list-disc">
                <li className="mb-4">
                  <p className="hover:underline">
                    No. 4/26 Vasugi Street, Manimangalam, Kanchipuram District,
                    Chennai - 601 301.
                  </p>
                </li>
                <li className="mb-4">
                  <p className="hover:underline">
                    thirulogchand@yahoo.com
                  </p>
                </li>
                <li className="mb-4">
                  <p className="hover:underline">
                    9840406431
                  </p>
                </li>
              </ul>
            </div>
            <div className="md:col-start-9 md:col-end-12">
              <span className="text-base font-bold tracking-wide">Social</span>
              <div className="flex items-center mt-1 space-x-3">
                <a
                  href="/"
                  className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                  aria-label="twitter-link"
                >
                  <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                    <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"></path>
                  </svg>
                </a>
                <a
                  href="/"
                  className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                  aria-label="instagram-link"
                >
                  <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                    <circle cx="15" cy="15" r="4"></circle>
                    <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100057085915208&mibextid=2JQ9oc"
                  className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
                  aria-label="Facebook-link"
                >
                  <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                    <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"></path>
                  </svg>
                </a>
              </div>
              <p className="mt-4 text-sm text-gray-500">
                Subscribe to our newsletter of follow us on the social channels
                to stay tuned.
              </p>
            </div>
          </div>
          <div className="px-4 py-6 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
              © 2023 {en.companyName}™. All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
