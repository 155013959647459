import React from "react";
import TitleWithLines from "../utils/TitleWithLines";

export default function Service() {
  return (
    <>
      <div className="pt-10">
        <div className="grid grid-col-12 mb-8">
          <div className="text-center col-start-5 font-bold pb-5">
            <h1 className="uppercase antialiased">Top Service</h1>
            <TitleWithLines
              title={"We provide the best experience"}
              textColor={"text-gray-700"}
              isTwoSide={true}
            />
          </div>
          <div className="col-start-5 md:col-start-8">
            <button className="text-white px-5 py-2 rounded bg-gradient-to-r from-indigo-800 to-indigo-700 hover:from-indigo-700 hover:to-indigo-800">
              <a href='/service' className="font-bold text-white hover:text-gray-200">{"See More >>"}</a>
            </button>
          </div>
        </div>
        <div class="grid mb-8 px-24 dark:border-gray-700 md:mb-6 md:grid-cols-4">
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                TREKKING
              </h3>
              <p class="my-4">Usually treks are organized with a known guide</p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                PARASAILING
              </h3>
              <p class="my-4">
                Parasailing is a recreational activity where a person
              </p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b  border-gray-200 border-l-0 md:border-l md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                RIVER CROSSING
              </h3>
              <p class="my-4">
                mountaineering technique used to cross the natural barriers
              </p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                BRAIN GYM
              </h3>
              <p class="my-4">It is really challenging increase the power</p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-gray-200  border-b md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                KNOT CRAFT
              </h3>
              <p class="my-4">Knot craft is a complex</p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200  md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                FAMILY CAMPS
              </h3>
              <p class="my-4">
                The camp also provides families with Joy-sharing
              </p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center bg-white border-gray-200 border-t  dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                SUMMER CAMPS
              </h3>
              <p class="my-4">
                It will be a mixture of adventure cum fun activities
              </p>
            </blockquote>
          </figure>
          <figure class="flex flex-col items-center justify-center p-8 text-center border-l bg-white border-gray-200 border-t border-l-0 md:border-t-0 md:border-l dark:bg-gray-800 dark:border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                BUSH CRAFT
              </h3>
              <p class="my-4">
                unknown and unstable which make it much more thrilling
              </p>
            </blockquote>
          </figure>
        </div>
      </div>
    </>
  );
}
