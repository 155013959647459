import {en} from '../../constants/common'
const tiers = [
  {
    id: "tier-hobby",
    name: "Basic Rock climbing Course ",
    href: `mailto:${en.mailId}?subject=Registration%20-%20Basic Rock climbing Course%20&body=Hi%2C%20%0ARegistering%20the%20course%20%E2%80%9CBasic%20Rock%20climbing%20Course%E2%80%9D%0A%0ARegards`,
    priceMonthly: 3000,
    description: "Timing: 6:30 am to 9:30 am",
    duration:"Duration: 6 days weekend ",
    features: [
      "1.Bouldering",
      "2.Basic Rock climbing Techniques",
      "3. Rappelling",
      "4. Jumaring",
      "5. Knots craft",
      "6. Trekking ",
      "7. Commando Climbing",
    ],
  },
  {
    id: "tier-team",
    name: "Advance Rock climbing course",
    href: `mailto:${en.mailId}?subject=Registration%20-%20Advance Rock climbing course%20&body=Hi%2C%20%0ARegistering%20the%20course%20%E2%80%9CAdvance%20Rock%20climbing%20Course%E2%80%9D%0A%0ARegards`,
    priceMonthly: 5000,
    description: "Timing: 6:30 am to 9:30 am ",
    duration: "Duration: 4days ( Saturday & Sunday)",
    features: [
      "1. River Crossing",
      "2. Advance Rock climbing Techniques",
      "3. Map reading",
      "4. Mountain Hazards",
      "5. LEAD climbing",
      "6. High Tope activities",
      "7. Zip line Training",
    ],
  },
];

export default function Pricing() {
  return (
    <div className="bg-gray-900 rounded">
      <div className="relative overflow-hidden pb-96 lg:pt-10">
        <div>
          <img
            className="absolute bottom-0 left-1/2 w-[1440px] max-w-none -translate-x-1/2"
            src="https://tailwindui.com/img/component-images/grid-blur-purple-on-black.jpg"
            alt=""
          />
        </div>
        <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="text-lg font-semibold leading-8 text-indigo-400">
              Latest Programs
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white">
              Explore the unexplored world
            </p>
            <p className="mt-6 text-lg leading-8 text-white/60">
              We personally visit the client and detail the programme and
              requirements and further procedures like place, duration, no. of
              participants. Join us now
            </p>
            <div className="pt-5">
              <button className="rounded-full py-3 px-6 text-white font-bold bg-gradient-to-r from-indigo-800 to-indigo-700 hover:from-indigo-700 hover:to-indigo-800">
                <a href="/contact">Join Us Now</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flow-root bg-white">
        <div className="relative -mt-80">
          <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-3xl bg-white shadow-xl ring-1 ring-black/10"
                >
                  <div className="p-8 sm:px-10 sm:pt-10 sm:pb-2">
                    <h3
                      className="text-lg font-semibold leading-8 tracking-tight text-indigo-600"
                      id={tier.id}
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline text-5xl font-bold tracking-tight text-gray-900">
                      ₹{tier.priceMonthly}
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <p className="text-base leading-7 text-gray-600">
                      {tier.duration}
                    </p>
                  </div>
                  <div className="flex flex-1 flex-col p-2">
                    <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 p-2 sm:px-8">
                      <ul className="space-y-2">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0"></div>
                            <p className="ml-3 text-sm leading-6 text-gray-600 font-semibold">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-8">
                        <a
                          href={tier.href}
                          className="inline-block w-full rounded-lg bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-indigo-700"
                          aria-describedby={tier.id}
                        >
                          Get started today
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
