import React from 'react';
import Navbar from '../../components/navbar';
import Belif from '../../components/news';
import Footer from '../../components/footer'
import { en } from '../../constants/common';

export default function Aboutus() {
  const { aboutUs } = en;
  return (
    <>
      <div
        className='w-full bg-cover bg-center'
        style={{
          height: '100%',
          backgroundImage: 'url(/image/aboutUsBanner.png)',
        }}
      >
        <div className='py-28'>
          <Navbar />
          <div className='flex justify-center'>
            <p className='text-xl text-center md:text-4xl lg:text-6xl font-bold text-white w-96'>
              {aboutUs.name}
            </p>
          </div>
        </div>
      </div>
      <div className='py-10'>
        <div className='grid grid-cols-12'>
          <div className='col-start-2 col-end-11 md:col-start-2 md:col-end-6'>
            <h1 className='text-xl font-bold md:text-3xl'>{aboutUs.header}</h1>
            <p className='py-2 text-justify md:text-left'>{aboutUs.subParagraph}</p>
          </div>
          <div className='col-start-2 col-end-11 py-10 md:col-start-7 md:col-end-11'>
            <img className='h-96' src={'image/about.png'} alt='banner' />
          </div>
        </div>
      </div>
      <div className='bg-gray-100 rounded-b-[260px]'>
        <div className='pb-20 pt-5'>
          <div className='grid grid-cols-12'>
            <div className='col-start-2 col-end-11 md:col-start-4 md:col-end-10 text-center'>
              <h1 className={'text-xl font-bold md:text-3xl'}>
                {aboutUs.headerTwo}
              </h1>
              <p>{aboutUs.subParagraphTwo}</p>
            </div>
            <div className='col-start-4 col-end-10 md:col-start-4 md:col-end-10 pt-5'>
              <img src={'image/visson.png'} alt={'visson'} />
            </div>
          </div>
        </div>
      </div>
      <div className='py-24'>
        <div className='grid grid-cols-12'>
          <div className='col-start-2 col-end-11 md:col-start-2 md:col-end-6'>
            <div>
              <img src='image/group.png' alt='aboutus' />
            </div>
          </div>
          <div className='col-start-2 col-end-11 md:col-start-7 md:col-end-11'>
            <p className='text-xl font-bold md:text-3xl'>
              {aboutUs.aimsHaeder}
            </p>
            <ul className='list-outsid list-disc pt-8'>
              {aboutUs.aimList.map((item, index) => (
                <li key={index} className='pt-5'>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className='pb-20 pt-5'>
          <div className='grid grid-cols-12'>
            <div className='col-start-2 col-end-11 md:col-start-4 md:col-end-10 text-center'>
              <h1 className={'text-xl font-bold md:text-3xl'}>
                {aboutUs.belifHeader}
              </h1>
              <p>{aboutUs.belifParagraph}</p>
            </div>
            <div className='col-start-2 col-end-12 pt-5'>
              <div className=' py-24 overflow-x-hidden'>
                <Belif />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
