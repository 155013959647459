import React from "react";
import { en, images } from "../../constants/common";
import "./home.css";
import Navbar from "../../components/navbar";
import Section from "../../components/home/section";
import AboutUs from "../../components/home/aboutus";
import Team from "../../components/team";
import Service from "../../components/home/service";
import Logo from "../../components/logo";
import Footer from "../../components/footer";

export default function Home() {
  const { teams } = images;

  return (
    <>
      <header>
        <div
          className="w-full bg-cover bg-center"
          style={{ height: "100%", backgroundImage: "url(/image/banner.png)" }}
        >
          <Navbar />
          <div className="pt-20 md:pt-40">
            <div className="grid grid-cols-12 content-center h-full w-full">
              <div className="col-start-2 col-end-12 md:col-start-2 md:col-end-6">
                <p className="text-xl md:text-4xl lg:text-6xl font-bold text-white md:pr-12">
                  Well Wishers <br /> Adventure <br /> Club
                </p>
                <p className="text-sm text-white pb-10 md:pb-5 md:pt-10">{en.subParagraph}</p>
              </div>
              <div className="col-start-2 col-end-7 pb-5 text-white text-lg font-semibold md:col-start-7 md:col-end-12 md:text-2xl lg:3xl">
                {[
                  { text: "Altitude", value: "1353m" },
                  { text: "Tracks", value: "6" },
                  { text: "Tourists / year", value: "1640+" },
                ].map((item, index) => (
                  <ul className="list-none text-sm" key={item.value}>
                    <li key={index}>{item.text}</li>
                    <li className="text-xl md:text-4xl lg:text-5xl font-bold pt-2 md:pb-4">
                      {item.value}
                    </li>
                  </ul>
                ))}
              </div>
            </div>
            <div className="w-full overflow-y-auto pb-2 md:pt-32 md:pb-5 custom-scrollbar">
              <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none ">
                {images.imageList.map((item, index) => (
                  <li key={index}>
                    <img src={item.image} alt={item.alt} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="pb-5"></div>
          </div>
        </div>
      </header>
      <Section />
      <AboutUs />
      <Team
        imageList={teams || []}
        header={"The treks of"}
        headerBreak={"our team"}
        subParagraph={"We ensure to insure on all our major mountaineering"}
        subParagraphHeader={"programmes with best insurance company."}
      />
      <Service />
      <div className="px-24 pb-24 overflow-x-hidden">
        <Logo />
      </div>
      <Footer />
    </>
  );
}
