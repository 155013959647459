export const en = {
  companyName: "Well Wishers Adventure Club",
  bannerParagraph: "Well Wishers Adventure  Club",
  mailId: "thirulog@wwadventureclub.com,kalai@wwadventureclub.com,charumathi@wwadventureclub.com",
  subParagraph:
    "Discover Untamed Beauty, Unleash Your Spirit, and Forge Your Path. Every Step, a Story; Every Trail, an Adventure. Find Your Footprints in the Wild, in Trekking, We Thrive.",
  serviceHeader: "Our topnotch Services",
  aboutUs: {
    name: "About Us",
    header: "Introduction",
    subParagraph:
      "Introduction Well-wishers adventure club is an organization started to create awareness about the adventure and mountaineering sports among the youngsters of Tamil Nadu. It was started way back in 1998 and later registered under societies Act on 21st May 2007, by few young mountaineers of Chennai who had training and wanted to bring these adventurous and exciting activities among the youth.Mr. R.Thirulogachandran the founder of the organization was a champion mountaineer and fully qualified trainer in Tamil Nadu started the organization with Mr.B.Srikanth (GM – IT Aviation), Mr.P.Nagarajan, Educator, Mr.M.Ilango,Out bound Trainer, Prakash, Mountaineer, Ex.Indian Navy Inspector Mr.Satish Khanna, Mountaineering Instructor, Mr.Srinivasan, Mountaineer, Vellore and other few friends. Over the period of time it has grown and our services and programmes were extended to any group now,like school , college, corporate and to general public who have real interest and spirit to dare and feel the inner sense of adventure. From time to time the organization has conducted trekking programmes, mountaineering training and outdoor games for schools, MNCs and even tied up with other organization ( NGOs) to train and conduct events. As we see there is a real need among school students and young adults to be with nature and also learn the missing links with nature through physical-pshyco-social activities. Its our journey to identify, encourage and include our vision to bring learning communities to partake the rich bewilder ness through the sense of adventure, we propose our project to the like minds.",
    headerTwo: "MISSION AND OUR VISION",
    subParagraphTwo:
      "To make individuals healthy, enthusiastic and responsible to understand the importance of good health, the nature and a life with happiness and adventure. It is also to create awareness of wildlife, the natural world and to be with nature for a while in our so called developed and civilized world.",
    aimsHaeder: "AIMS AND OBJECTIVES",
    aimList: [
      "To achieve a state of balance through physical, emotional, psychological and social activities.",
      "To break barrier of mundane world and be with nature and understand the subtlety.",
      "Creating better human understanding, working for social development and taking up simple responsibilities to protect and preserve the natural world.",
      "Developing life skills, Personality and Self understanding through adventure activities.",
      "To develop better attitudes towards risk taking, dealing with challenging situations, cooperative leadership and building better health.",
    ],
    belifHeader: "OUR BELIEF AND OUR STRENGTH",
    belifParagraph:
      "Life is an adventure dare it. Human life is so unique and sublime the real cause of our existence need to be understood and that can happen only when we are with nature. Coordinated, special training, safety, protection, well being and regularity are the impetus to succeed.",
  },
  contactUs: {
    name: "Contact Us",
  },
};

export const images = {
  imageList: [
    { image: "/image/img1.png", alt: "image 1" },
    { image: "/image/img2.png", alt: "image 1" },
    { image: "/image/img3.png", alt: "image 1" },
    { image: "/image/img1.png", alt: "image 1" },
    { image: "/image/img2.png", alt: "image 1" },
    { image: "/image/img3.png", alt: "image 1" },
    { image: "/image/img1.png", alt: "image 1" },
  ],
  imageListSection: [
    {
      image: "/image/img1.png",
      alt: "image 1",
      description: [
        "1.Bouldering",
        "2.Basic Rock climbing Techniques",
        "3. Rappelling",
        "4. Jumaring",
        "5. Knots craft",
        "6. Trekking ",
        "7. Commando Climbing",
      ],
    },
    {
      image: "/image/img2.png",
      alt: "image 2",
      description: [
        "1. River Crossing",
        "2. Advance Rock climbing Techniques",
        "3. Map reading",
        "4. Mountain Hazards",
        "5. LEAD climbing",
        "6. High Tope activities",
        "7. Zip line Training",
      ],
    },
  ],
  teams: [
    {
      image: "/image/team1.png",
      alt: "Team1",
      name: "Bianco",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/team2.png",
      alt: "Team2",
      name: "Civetta",
      subName: "Dolomiti, Italy",
    },
    {
      image: "/image/team3.png",
      alt: "Team3",
      name: "Teton",
      subName: "Wyoming, USA",
    },
  ],
  serviceImages: [
    {
      image: "/image/long1.png",
      alt: "long1",
      name: "Bianco",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/long2.png",
      alt: "long2",
      name: "Civetta",
      subName: "Dolomiti, Italy",
    },
    {
      image: "/image/long3.png",
      alt: "long3",
      name: "Teton",
      subName: "Wyoming, USA",
    },
  ],
  flipImage: [
    {
      image: "/image/flip1.png",
      alt: "Team1",
      name: "Corporate Outbound Training",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip2.png",
      alt: "Team1",
      name: "MT. Kailash Yatra",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip3.png",
      alt: "Team1",
      name: "Chess",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip4.png",
      alt: "Team1",
      name: "Silambam Course ",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip5.png",
      alt: "Team1",
      name: "Rock Climbing course",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip6.png",
      alt: "Team1",
      name: "Niligiries Expedition",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip7.png",
      alt: "Team1",
      name: "ECA for schools and colleges",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip8.png",
      alt: "Team1",
      name: "MT. Everest Base Camp",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip9.png",
      alt: "Team1",
      name: "Roller Skating",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip10.png",
      alt: "Team1",
      name: "Indian Himalaya Expedition",
      subName: "Alpes, Italy",
    },
    {
      image: "/image/flip11.png",
      alt: "Team1",
      name: "Yoga & Meditation",
      subName: "Alpes, Italy",
    },
  ],
};

export const logos = [
  {name: 'image/partners/blueDart.png', alt: 'blue dart', size:'w-28'},
  {name: 'image/partners/jito.png', alt: 'jito'},
  {name: 'image/partners/lions.png', alt: 'lionsClub'},
  {name: 'image/partners/network.jpg', alt: 'network'},
  {name: 'image/partners/rotary.png', alt: 'rotary club'},
  {name: 'image/partners/school.png', alt: 'school'},
  {name: 'image/partners/BabajiVidyashram.jpg', alt: 'BabajiVidyashram'},
  {name: 'image/partners/BrighterMinds.webp', alt: 'BrighterMinds'},
  {name: 'image/partners/LadyAndal.png', alt: 'LandyAndal'},
  {name: 'image/partners/SRMUniversity.png', alt: 'SRMUniversity'},
]
export const logoReverse = [
  {name: 'image/partners/trekking.jpg', alt: 'trekking', size:'w-28'},
  {name: 'image/partners/maxwell.webp', alt: 'maxwell', size:'w-28'},
  {name: 'image/partners/chinmaya.jpg', alt: 'chinmaya', size:'w-28'},
  {name: 'image/partners/MontfortSchool.jpg', alt: 'MantfortSchool', size:'w-20 h-24'},
  {name: 'image/partners/PonVidyamandir.jpg', alt: 'PonVidyamandir', size:'w-20 h-24'},
  {name: 'image/partners/srdf.png', alt: 'srdf', size:'w-20 h-24'},
  {name: 'image/partners/rotary.png', alt: 'rotary club', size:'w-28'},
  {name: 'image/partners/school.png', alt: 'school', size:'w-28'},
  {name: 'image/partners/scope.jpg', alt: 'scope', size:'w-28'},
]