// Routes.js

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./useRoutes";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
};

export default AppRoutes;
