import React from 'react';

const TitleWithLines = ({ title, textColor, isTwoSide }) => {
  return (
    <div className="flex justify-center items-baseline">
      <div className="w-8 border-t border-r border-l border-blue-600 h-0 border-4 rounded-lg"></div>
      <h2 className={`${textColor}  text-center mx-2`}>{title}</h2>
      {isTwoSide && <div className="w-8 border-t border-r border-l border-blue-600 h-0 border-4 rounded-lg"></div>}
    </div>
  );
};

export default TitleWithLines;
