import Front from "./front";
import Back from "./back";
import {en} from '../../constants/common'

export default function FlipHover({ image, name }) {
  return (
    <div className="relative w-96 h-60 text-white overflow-hidden cursor-pointer transition-all duration-700 card md:w-full">
      <a href={`mailto:${en.mailId}?subject=Registration%20-%20${name}%20&body=register%20${name}`}>
        <Front image={image} /> <Back name={name} />
      </a>
    </div>
  );
}
