import React from "react";
import TitleWithLines from "../utils/TitleWithLines";
import FlipHover from "./flip";
import { images } from "../../constants/common";

export default function OurService() {
  const { flipImage } = images;
  return (
    <>
      <div className="py-10">
        <div className="text-center font-bold pb-5">
          <h1 className="uppercase antialiased">Best Services</h1>
          <TitleWithLines
            title={"We provide the best services"}
            textColor={"text-gray-700"}
            isTwoSide={true}
          />
        </div>
        <div class="grid gap-5 mb-8  dark:border-gray-700 md:mb-12 grid-cols-1 md:px-24 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {flipImage.map((item, index) => (
            <div key={index}>
              <FlipHover image={item.image} name={item.name} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
