import React from "react";
import { images } from "../../constants/common";
import Navabar from '../../components/navbar'
import Team from "../../components/team";
import Footer from "../../components/footer";
import OurService from "../../components/ourService/ourService";

export default function Services() {
  const { serviceImages } = images;
  return (
    <>
      <header>
        <div className="w-full bg-cover bg-center">
         <Navabar />
        </div>
      </header>
      <Team
        imageList={serviceImages}
        header={"Our topnotch"}
        headerBreak={"Services"}
        subParagraph={
          "We ensure to insure on all our major mountaineering programmes"
        }
        subParagraphHeader={"with best insurance company"}
      />
      <OurService />
      <Footer />
    </>
  );
}
