import React, { useState } from "react";

const Accordion = () => {
  const [listState, setListState] = useState({
    first: false,
    second: false,
    third: false,
  });

  const toggleAccordion = (listNumber) => {
    setListState((prevState) => {
      const updatedState = { ...prevState };
      // Set the clicked list to true
      updatedState[listNumber] = !listState[listNumber];
      // Set all other lists to false
      for (const key in updatedState) {
        if (key !== listNumber) {
          updatedState[key] = false;
        }
      }

      return updatedState;
    });
  };

  return (
    <div className="lg:w-1/3">
      <div
        id="accordion-flush"
        data-accordion="collapse"
        data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
        data-inactive-classes="text-gray-500 dark:text-gray-400"
      >
        <h2 id="accordion-flush-heading-1">
          <button
            type="button"
            className={` ${
              listState.first
                ? "font-bold text-blue-800"
                : "font-bold text-gray-600"
            } flex items-center justify-between w-full py-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 dark:text-gray-400`}
            onClick={() => toggleAccordion("first")}
          >
            <span>Our mountains and our location</span>
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>
        <div
          className={`${
            listState.first ? "block bg-white rounded-lg" : "hidden"
          } text-gray-900 transition-opacity duration-700`}
        >
          <div className="py-5 px-5 border-b  border-gray-200 dark:border-gray-700">
            <li>Adventure Course (Rock Climbing & Trekking)</li>
            <li>Corporate Outbound</li>
            <li>Adventure Camps Corporate Soft skill Training</li>
            <li>Family Trekking Camp</li>
          </div>
        </div>
        <h2 id="accordion-flush-heading-2">
          <button
            type="button"
            className={`${
              listState.second
                ? "font-bold text-blue-800"
                : "font-bold text-gray-600"
            } flex items-center justify-between w-full py-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 dark:text-gray-400`}
            onClick={() => toggleAccordion("second")}
          >
            <span>About our treks and trips</span>
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>
        <div
          className={`${
            listState.second ? "block bg-white rounded-lg" : "hidden"
          } text-gray-900 transition-opacity duration-700`}
        >
          <div className="py-5 px-5 border-b  border-gray-200 dark:border-gray-700">
            <li>
              Providing unforgettable adventures in breathtaking locations.
            </li>
            <li>Expert guides with a passion for exploration and safety.</li>
            <li>Committed to sustainable and responsible travel practices.</li>
            <li>
              Hear from our satisfied travelers - their stories, our pride.
            </li>
          </div>
        </div>
        <h2 id="accordion-flush-heading-3">
          <button
            className={`${
              listState.third
                ? "font-bold text-blue-800"
                : "font-bold text-gray-600"
            } flex items-center justify-between w-full py-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 dark:text-gray-400`}
            onClick={() => toggleAccordion("third")}
          >
            <span>About our team and guests</span>
            <svg
              data-accordion-icon
              class="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </button>
        </h2>
        <div
          id="accordion-flush-body-3"
          className={`${
            listState.third ? "block bg-white rounded-lg" : "hidden"
          } text-gray-900 transition-opacity duration-700`}
        >
          <div className="py-5 px-5 border-b  border-gray-200 dark:border-gray-700">
            <li>
            Meet our passionate team dedicated to crafting unique adventures.
            </li>
            <li>Our diverse guests bring their stories and backgrounds to enrich our journeys.</li>
            <li>Shared adventures that foster lasting connections and memories.</li>
            <li>
            A tight-knit community united by shared values and love for adventure.
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
