import { useRoutes } from 'react-router-dom';
import Home from "../pages/home";
import Service from "../pages/service";
import Aboutus from "../pages/aboutus";
import Contact from "../pages/contact";

export const MyRoutes = () => {
  const routes = [
    { path: '/', element: <Home/> },
    { path: '/service', element: <Service /> },
    { path: '/aboutus', element: <Aboutus /> },
    { path: '/contact', element: <Contact /> },
  ];

  return useRoutes(routes);
};